* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

:root {
  /* last 2 character hex equivalent of alpha value in rgba format*/
  /* 0.2 = 33 */
  /* 0.3 = 4d */
  /* 0.5 = 80 */
  /* 0.7 = b3 */
  /* 0.8 = cc */
  /* 0.9 = e6 */

  --black: #092947;
  --sidebar-hider: #092947f6;
  --white: #fafdff;

  /* hero drawn boxes, experience content boxes */
  --white-opaque: #fefffa80;

  --main: #c7ff4f;
  --main-transparent: #c7ff4f00;
  --main-muted: #c7ff4f;
  --main-bg: #e7fbf9e2;
  --main-7: #c7ff4f33; /* project card background */
  --cube: #c6e1facc;
  --big-cube: #c6e1facc;

  --hamburger: 0.5s;
  --info: 0.7s;
  --hero: 2.5s;
  --hero-delay: 2s;

  --small-border: 0.2rem solid var(--black);

  --cube-width: 5.3rem;
  --big-cube-width: 10rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 1rem;
}

::-webkit-scrollbar:horizontal {
  height: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--black);
}

::-webkit-scrollbar-track {
  background-color: var(--white);
}

@media (min-width: 480px) {
  :root {
    --hero: 2.5s;
    --hero-delay: 2s;
  }
}

@media (min-width: 992px) {
  :root {
    --big-cube-width: 12rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --big-cube-width: 15rem;
  }
}
